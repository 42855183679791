import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import banksData from '../../data/banks.json';
import appConfig from '../../utils/appConfig';
import axios from 'axios';
import { SelectChangeEvent } from '@mui/material';

interface Bank {
  id: number;
  name_he: string;
  name_en: string;
}

interface Check {
  checkDate: string;
  checkAmount: string;
  bank: string;
  branchNumber: string;
  accountNumber: string;
  checkNumber: string;
}

interface CashFormData {
  paymentType: string;
  paymentAmount: string;
  idNumber: string;
  receiptName: string;
  email: string;
}

interface CheckFormData {
  paymentType: string;
  paymentAmount: string;
  idNumber: string;
  receiptName: string;
  email: string;
  checks: Check[];
}

const CashOrCheckPay: React.FC = () => {
  const location = useLocation();
  const formData = location.state?.formData;
  const [paymentType, setPaymentType] = useState<string>('');
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isLoading, setIsLoading] = useState(false);
  const type = 'צהרון';
  const responseData = location.state?.responseData;

  const [cashFormData, setCashFormData] = useState<CashFormData>({
    paymentType: 'מזומן',
    paymentAmount: '',
    idNumber: formData?.parents.parentId || '',
    receiptName: formData?.parents.parentName || '',
    email: formData?.parents.email || '',
  });
  const myAfternoon = location.state?.afternoon;
  const [afternoon, setAfternoon] = useState({});
  const [account, setAccount] = useState({});
  useEffect(() => {
    const storedAfternoon = localStorage.getItem('afternoon');
    const storedAccount = localStorage.getItem('account');
  
    if (storedAfternoon) {
      setAfternoon(JSON.parse(storedAfternoon));
    }
    if (storedAccount) {
      setAccount(JSON.parse(storedAccount));
    }
  }, []);


  const [checkFormData, setCheckFormData] = useState<CheckFormData>({
    paymentType: 'המחאה',
    paymentAmount: '',
    idNumber: formData?.parents.parentId || '',
    receiptName: formData?.parents.parentName || '',
    email: formData?.parents.email || '',
    checks: [{
      checkDate: '',
      checkAmount: '',
      bank: '',
      branchNumber: '',
      accountNumber: '',
      checkNumber: '',
    }],
  });

  const banks: Bank[] = banksData;

  const { authorityName } = useParams<{ authorityName: string }>();
  const authority = appConfig.authorities.find(auth => auth.en === authorityName);

  const handleInputChange = (paymentType: 'מזומן' | 'המחאה', name: string) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;

    if (name === "accountOwnerName" && !/^\d*$/.test(value)) {
      setErrors(prevErrors => ({ ...prevErrors, [name]: 'ניתן להקיש ספרות בלבד' }));
      return;
    } else {
      setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
    }

    if (paymentType === 'מזומן') {
      setCashFormData(prevData => ({
        ...prevData,
        [name]: value
      }));
    } else if (paymentType === 'המחאה') {
      setCheckFormData(prevData => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleCheckChange = (index: number, field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setCheckFormData(prevData => {
      const newChecks = prevData.checks.map((check, i) =>
        i === index ? { ...check, [field]: value } : check
      );

      const totalCheckAmount = newChecks.reduce((sum, check) => {
        return sum + parseFloat(check.checkAmount || '0');
      }, 0);

      return {
        ...prevData,
        checks: newChecks,
        paymentAmount: totalCheckAmount.toString(),
      };
    });
  };

  const handleBankChange = (index: number) => (event: SelectChangeEvent<number>) => {
    const bankId = event.target.value as number;
    const selectedBank = banks.find(bank => bank.id === bankId) || { id: 0, name_he: '' };

    setCheckFormData(prevData => {
      const newChecks = [...prevData.checks];
      newChecks[index] = {
        ...newChecks[index],
        bank : `מספר בנק: ${selectedBank.id},שם בנק: ${selectedBank.name_he}`,
      };
      return {
        ...prevData,
        checks: newChecks,
      };
    });
  };

  // const appendFormData = (data: any, formData: FormData, root: string = ''): void => {
  //   for (const key in data) {
  //     if (data.hasOwnProperty(key)) {
  //       const value = data[key];
  //       const formKey = root ? `${root}[${key}]` : key;

  //       if (typeof value === 'object' && !(value instanceof File)) {
  //         appendFormData(value, formData, formKey);
  //       } else {
  //         formData.append(formKey, value);
  //       }
  //     }
  //   }
  // };

  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    event.preventDefault();

    // הגדרת timeout של 10 שניות כדי להפעיל מחדש את הכפתור
    setTimeout(() => {
      setIsLoading(false);
    }, 10000);

    const currentFormData: any = {
      terminal_name:authority?.tranzilaCode,
      ////document_date: '',
      //document_type: '',
      //action: 1,
      document_language: 'heb',
      document_currency_code: 'ILS',
      vat_percent: 17,
      //client_company: formData.clientCompany,
      client_address_line_1: formData ?`${formData.address.city} ${formData.address.street} ${formData.address.number}`:`${responseData?.city} ${responseData?.street} `,

      //client_address_line_2: formData.clientAddressLine2,
      //client_zip: formData.clientZip,
      //client_city: formData.clientCity,
      ///client_country_code: 'IL',
      //items: formData.items,
      paymentType:paymentType,
      paymentAmount:paymentType==='מזומן'?cashFormData.paymentAmount:checkFormData.paymentAmount,
      type:type,

      user_defined_7: formData ? `תשלום עבור צהרון - ${formData.firstName} ${formData.lastName}` : `תשלום עבור צהרון - ${responseData?.firstName} ${responseData?.lastName}` ,
      pdesc: formData ? `תשלום עבור צהרון - ${formData.firstName} ${formData.lastName}` : `תשלום עבור צהרון - ${responseData?.firstName} ${responseData?.lastName}` ,
      ID_child: formData?.studentId || responseData?.id_child,
      mosadName: myAfternoon?.Name || responseData?.mosad2,

      payments: []
    };

    if (paymentType === 'מזומן') {
      currentFormData.client_id = formData ?formData.parents.parentId || cashFormData.idNumber:responseData?.IdNumber;
      currentFormData.client_name = formData ? formData.parents.parentName || cashFormData.receiptName:responseData?.parentName;
      currentFormData.client_email = formData ? formData.parents.email || cashFormData.email:responseData?.email;
      
      currentFormData.payments.push({
        payment_method: 5,
        payment_date: '',
        amount: cashFormData.paymentAmount,
        currency_code: 'ILS',
        other_description: formData ? `תשלום צהרון עבור ${formData.firstName} ${formData.lastName} על סך ${cashFormData.paymentAmount} שקלים`:
                                      `תשלום צהרון עבור ${responseData?.firstName} ${responseData.lastName} על סך ${cashFormData.paymentAmount} שקלים`,
      });
    } else if (paymentType === 'המחאה') {

      currentFormData.client_id =formData ? formData.parents.parentId || checkFormData.idNumber:responseData?.IdNumber;
      currentFormData.client_name =formData ? formData.parents.parentName || checkFormData.receiptName:responseData?.parentName;
      currentFormData.client_email =formData ? formData.parents.email || checkFormData.email:responseData?.email;
      currentFormData.other_description=formData ? `תשלום צהרון עבור ${formData.firstName} ${formData.lastName} על סך ${checkFormData.paymentAmount} שקלים`:
                                                    `תשלום צהרון עבור ${responseData?.firstName} ${responseData.lastName} על סך ${cashFormData.paymentAmount} שקלים`;

      checkFormData.checks.forEach((check) => {
        currentFormData.payments.push({
          payment_method: 3,
          payment_date: check.checkDate,
          amount: check.checkAmount,
          currency_code: 'ILS',
          bank: check.bank,
          branchNumber:check.branchNumber,
          accountNumber:check.accountNumber,
          checkNumber:check.checkNumber,
          other_description: `בנק: ${check.bank} סניף: ${check.branchNumber} מספר חשבון: ${check.accountNumber} מספר המחאה: ${check.checkNumber}`,
        });
      });
    }

    try {
      const dataToSend = {
        currentFormData,
        account,
        formData,
        afternoon
       };
        console.log("tempData to hagay", dataToSend);
        axios.post(appConfig.cashOrCheckPaymentTempUrl, dataToSend)
        .then(response => {
          console.log('Data sent successfully:', response.data);
        })
        .catch(error => {
          console.error('Error sending data:', error);
        });
      const documentResponse = await fetch(`${appConfig.apiUrls.baseUrl}/tranzilaRoutes/createDocument`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(currentFormData),
      });

      if (documentResponse.ok) {
        const responseData = await documentResponse.json();
        console.log(responseData.data.document);
        const tranzilaDocumentData= responseData.data.document;

        const retrievalKey = responseData.data.document.retrieval_key;
        const payType= currentFormData?.paymentType
        const productType=currentFormData?.type
        const dataToSend = {
          payType,
          productType,
          tranzilaDocumentData,
          currentFormData,
          account,
          formData,
          afternoon
         };

        console.log("data to hagay", dataToSend);
        axios.post(appConfig.cashOrCheckPaymentUrl, dataToSend)
          .then(response => {
            console.log('Data sent successfully:', response.data);
          })
          .catch(error => {
            console.error('Error sending data:', error);
          });

        navigate(`/${authority?.en}/thankYouPageCashOrCheck`, { state: { success: true, retrievalKey } });
      } else {
        console.error('Failed to create document');
        navigate(`/${authority?.en}/thankYouPageCashOrCheck`, { state: { success: false } });
      }
    } catch (error) {
      console.error('Error sending data:', error);
      navigate(`/${authority?.en}/thankYouPageCashOrCheck`, { state: { success: false } });
    }
  };


  
  

  const addNewCheck = () => {
    setCheckFormData((prevData) => {
      const firstCheck = prevData.checks[0];
      return {
        ...prevData,
        checks: [
          ...prevData.checks,
          {
            checkDate: '',
            checkAmount: '',
            bank: firstCheck.bank,
            branchNumber: firstCheck.branchNumber,
            accountNumber: firstCheck.accountNumber,
            checkNumber: firstCheck.checkNumber,
          },
        ],
      };
    });
  };
  
  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };
  const getMinDate = () => {
    const currentDate = new Date();
    const minDate = new Date(currentDate.setMonth(currentDate.getMonth() - 3));
    return minDate.toISOString().split('T')[0]; // החזרת התאריך בפורמט YYYY-MM-DD
  };
  
  
  return (
    <Box sx={{ maxWidth: 400, margin: '0 auto', padding: 2 }}>
      <Typography variant="h6" sx={{ marginBottom: 2, textAlign: 'center' }}>
        תשלום במזומן/ המחאה
      </Typography>
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel id="payment-type-label">סוג תשלום</InputLabel>
        <Select
          labelId="payment-type-label"
          id="payment-type-select"
          value={paymentType}
          onChange={(e) => setPaymentType(e.target.value)}
          label="סוג תשלום"
        >
          <MenuItem value="מזומן">מזומן</MenuItem>
          <MenuItem value="המחאה">המחאה</MenuItem>
        </Select>
      </FormControl>

      <form onSubmit={handleSubmit}>
        {paymentType === 'מזומן' && (
          <>
            <TextField
              fullWidth
              label="תעודת זהות"
              name="idNumber"
              value={cashFormData.idNumber}
              onChange={handleInputChange('מזומן', 'idNumber')}
              sx={{ marginBottom: 2 }}
              InputProps={{ style: { textAlign: 'right' } }}
              InputLabelProps={{ style: { textAlign: 'right', direction: 'rtl' } }}
              required
            />
            <TextField
              fullWidth
              label="שם לקבלה"
              name="receiptName"
              value={cashFormData.receiptName}
              onChange={handleInputChange('מזומן', 'receiptName')}
              sx={{ marginBottom: 2 }}
              InputProps={{ style: { textAlign: 'right' } }}
              InputLabelProps={{ style: { textAlign: 'right', direction: 'rtl' } }}
              required
            />
            <TextField
              fullWidth
              label="מייל לשליחת קבלה"
              name="email"
              type="email"
              value={cashFormData.email}
              onChange={handleInputChange('מזומן', 'email')}
              sx={{ marginBottom: 2 }}
              InputProps={{ style: { textAlign: 'right' } }}
              InputLabelProps={{ style: { textAlign: 'right', direction: 'rtl' } }}
              required
            />
            <TextField
              fullWidth
              label="סכום לתשלום"
              name="paymentAmount"
              value={cashFormData.paymentAmount}
              onChange={handleInputChange('מזומן', 'paymentAmount')}
              sx={{ marginBottom: 2 }}
              InputProps={{ style: { textAlign: 'right' } }}
              InputLabelProps={{ style: { textAlign: 'right', direction: 'rtl' } }}
              required
            />
          </>
        )}

        {paymentType === 'המחאה' && (
          <>
            <TextField
              fullWidth
              label="תעודת זהות"
              name="idNumber"
              value={checkFormData.idNumber}
              onChange={handleInputChange('המחאה', 'idNumber')}
              sx={{ marginBottom: 2 }}
              InputProps={{ style: { textAlign: 'right' } }}
              InputLabelProps={{ style: { textAlign: 'right', direction: 'rtl' } }}
              required
            />
            <TextField
              fullWidth
              label="שם לקבלה"
              name="receiptName"
              value={checkFormData.receiptName}
              onChange={handleInputChange('המחאה', 'receiptName')}
              sx={{ marginBottom: 2 }}
              InputProps={{ style: { textAlign: 'right' } }}
              InputLabelProps={{ style: { textAlign: 'right', direction: 'rtl' } }}
              required
            />
            <TextField
              fullWidth
              label="מייל לשליחת קבלה"
              name="email"
              type="email"
              value={checkFormData.email}
              onChange={handleInputChange('המחאה', 'email')}
              sx={{ marginBottom: 2 }}
              InputProps={{ style: { textAlign: 'right' } }}
              InputLabelProps={{ style: { textAlign: 'right', direction: 'rtl' } }}
              required
            />
           <div>
      {checkFormData.checks.map((check, index) => (
        <div key={index}>
          <TextField
            fullWidth
            label="תאריך ההמחאה"
            name={`checkDate-${index}`}
            type="date"
            value={check.checkDate}
            onChange={handleCheckChange(index, 'checkDate')}
            InputProps={{
              inputProps: {min:  getMinDate()}, // הגדרת max בתוך inputProps
              style: { textAlign: 'right' }
            }}
            sx={{ marginBottom: 2 }}
            InputLabelProps={{ style: { textAlign: 'right', direction: 'rtl' }, shrink: true }}
            required
          />
          <TextField
            fullWidth
            label="סכום ההמחאה"
            name={`checkAmount-${index}`}
            value={check.checkAmount}
            onChange={handleCheckChange(index, 'checkAmount')}
            sx={{ marginBottom: 2 }}
            InputProps={{ style: { textAlign: 'right' } }}
            InputLabelProps={{ style: { textAlign: 'right', direction: 'rtl' } }}
            required
          />
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel id={`bank-label-${index}`}>בנק</InputLabel>
            <Select
              labelId={`bank-label-${index}`}
              id={`bank-select-${index}`}

              value={check.bank && typeof check.bank === 'string' ? parseInt(check.bank.split(':')[1].split(',')[0].trim(), 10) : ''} // Ensure check.bank is defined and is a string

              onChange={handleBankChange(index)}
              label="בנק"
              MenuProps={{
                PaperProps: {
                  sx: {
                    '& .MuiMenuItem-root': {
                      textAlign: 'right',
                    },
                  },
                },
              }}
            >
              {banks.map((bank) => (
                <MenuItem key={bank.id} value={bank.id}>
                  {bank.name_he}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="סניף"
            name={`branchNumber-${index}`}
            value={check.branchNumber}
            onChange={handleCheckChange(index, 'branchNumber')}
            sx={{ marginBottom: 2 }}
            InputProps={{ style: { textAlign: 'right' } }}
            InputLabelProps={{ style: { textAlign: 'right', direction: 'rtl' } }}
            required
          />
          <TextField
            fullWidth
            label="מספר חשבון"
            name={`accountNumber-${index}`}
            value={check.accountNumber}
            onChange={handleCheckChange(index, 'accountNumber')}
            sx={{ marginBottom: 2 }}
            InputProps={{ style: { textAlign: 'right' } }}
            InputLabelProps={{ style: { textAlign: 'right', direction: 'rtl' } }}
            required
          />
          <TextField
            fullWidth
            label="מספר המחאה"
            name={`checkNumber-${index}`}
            value={check.checkNumber}
            onChange={handleCheckChange(index, 'checkNumber')}
            sx={{ marginBottom: 2 }}
            InputProps={{ style: { textAlign: 'right' } }}
            InputLabelProps={{ style: { textAlign: 'right', direction: 'rtl' } }}
            required
          />
        </div>
      ))}
      <Button variant="contained" onClick={addNewCheck} disabled={isLoading}  sx={{ marginBottom: 2 }}>
        הוסף המחאה
      </Button>
    </div>
          </>
        )}

        <Button type="submit" variant="contained" color="primary" fullWidth>
        {isLoading ? (
            'טוען...'
            ) : (
        'שלח'
      )}
        </Button>
      </form>
    </Box>
  );
};

export default CashOrCheckPay;

